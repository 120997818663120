.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.nsm7Bb-HzV7m-LgbsSe{
  margin-top: 1rem !important ;
  width: 575px !important;
  max-width: 500px !important;
  height: 70px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  text-align: left !important;
  padding-left: 160px !important;
}